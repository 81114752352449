const React = require("react")
const { Provider } = require("react-fela")
const { createRenderer } = require("fela")
const moduleInterop = require("module-interop").default

require("whatwg-fetch")

exports.onClientEntry = () => {
  // Don't need to do anything here, but if you don't
  // export something, the import won't work.
}

exports.wrapRootElement = ({ element }) => {
  let config
  try {
    config = moduleInterop(require(`./fela.config.js`))
  } catch (e) {
    console.log(e)
  }
  const renderer = createRenderer(config)

  return <Provider renderer={renderer}>{element}</Provider>
}
